import { Product } from "@models/models";
import { Field } from "@measured/puck";
import axios from "axios";

const field: Field<Product | undefined> = {
  type: "external",
  label: "Single Event",
  showSearch: true,
  getItemSummary: (item: Product) => item.title,
  mapRow: (item: Product) => ({
    Name: item.title,
  }),
  fetchList: async ({ query }) => {
    const result = await axios.get(
      `${process.env.NEXT_PUBLIC_ADS_SERVICE_BASE_URL}/api/events?account_id=${localStorage.getItem("accountId")}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("api_token")}`,
        },
        withCredentials: true,
      },
    );
    const products = result.data.data as Product[];
    return !query
      ? products
      : products.filter((b) => b.title.toLowerCase().includes(query));
  },
};

export default field;
